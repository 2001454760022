import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import AppDataContext from '../../context/AppDataContext';

const BuildingOverviewCTA = ({ link, heading, subheading, colour, imageUrl, imageAlt }) => {
  const { prevButtonRect } = useContext(AppDataContext);

  const [isSelected, setIsSelected] = useState(false);

  const handleOnClick = (e) => {
    const rect = e.currentTarget.getBoundingClientRect();
    prevButtonRect.current = rect;

    setIsSelected(true);
  };

  return (
    <article>
      <Link to={link}>
        <motion.div
          initial={{ scale: 0.8, y: 30, opacity: 0 }}
          whileInView={{ scale: 1, y: 0, opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5 }}

          onClick={handleOnClick}

          className={['btn', 'building-overview-cta', (isSelected) ? 'selected' : ''].join(' ')}
        >
          <div
            className='image-container'
          >
            <img src={imageUrl} alt={imageAlt} />
          </div>
          <div
            className={['row', 'button-head'].join(' ')}
          >
          </div>
          <div
            className={['row', 'button-body'].join(' ')}
            style={{
              backgroundColor: colour + 'f3'
            }}
          >
            <h4 dangerouslySetInnerHTML={{__html: heading}}></h4>
            <div className='location'>
              <p>{subheading}</p>
              <div
                className='arrow-icon icon-chevron-right'
              ></div>
            </div>
          </div>
        </motion.div>
      </Link>
    </article>
  );
};

BuildingOverviewCTA.propTypes = {
  link: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  subheading: PropTypes.string.isRequired,
  colour: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  imageAlt: PropTypes.string
};

export default BuildingOverviewCTA;