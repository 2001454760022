import React, { useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { motion } from 'framer-motion';
import AppDataContext from '../../context/AppDataContext';
import BuildingOverviewCTA from '../common/BuildingOverviewCTA';
import HeroImage from '../common/HeroImage';
import HomeLogo from '../building-logos/HomeLogo';
import Quote from '../common/Quote';
import Text from '../Text';
import { checkImage } from '../../utils/Utils';

const CollectionPage = () => {
  const { buildingId } = useParams();
  const { selectedLanguage, loadedAppData, setCurPage, getLocalizedString } = useContext(AppDataContext);

  /**
   * -The building over page
   * -Note that the header has a transparent background
   * -Display hero image with building logo animation below
   * -Render a description of the building
   * -Display a list of Levels/Themes buttons which goes to the corresponding "Level Overview" page
   * -Display a button to go the "Guided Tours" page
   * -Display a button to go the "About" page
   */

  useEffect(() => {
    // On page load set the current page in the app data context to track what page you are on
    const page = {
      type: 0,
      label: 'TELUS Sky Calgary' // This label is the building name, this needs to be pulled from the CMS
    };

    setCurPage(page);

    // On page load, ensure that the page is at the top
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant'
    });
  }, []);

  const buildPage = () => {
    //Get a reference to the desired data set for the page content
    const data = loadedAppData.current['collectionPage'].getJsonContent(selectedLanguage);

    const tourPageData = loadedAppData.current['tourOverviewPage'].getJsonContent(selectedLanguage);
    const aboutPageData = loadedAppData.current['aboutPage'].getJsonContent(selectedLanguage);

    //Get a reference to the levels data set
    const levelData = loadedAppData.current['levels'].getJsonContent(selectedLanguage);
    return (
      <>
        <header>
          <HeroImage imageUrl={checkImage(data.acf.hero_image)} altText='Collection Hero Image' shouldAnimate={false} />
          <HomeLogo />
        </header>
        <section>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 1 }}

            className='level-title tag steel-tag'
          >
            {getLocalizedString('label_welcome')}
          </motion.div>
          {
            // Add the buildingId as the class for custom background image per building
          }
          <div className={['content-section-container', buildingId].join(' ')}>
            {
              // Loop through and build out the different component types
              data.acf.content_sections.map((section, index) => {
                switch (section.acf_fc_layout) {
                  case 'text':
                    return <Text key={section.acf_fc_layout + '-' + index} heading={section.heading} content={section.content} bgStyle="opt1" />;
                  case 'quote':
                    return <Quote key={section.acf_fc_layout + '-' + index} quote={section.quote} name={section.quotee} />;
                  case 'message':
                    return <p key={section.acf_fc_layout + '-' + index}>Insert Message component here</p>;
                }
              })
            }
          </div>
        </section>
        <motion.section
          initial={{ y: 50 }}
          whileInView={{ y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1 }}
          className='levels-container'
        >
          <motion.div
            initial={{ opacity: 0, x: -100 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 1 }}
            className='level-title tag bronze-tag'>{getLocalizedString('label_levels')}</motion.div>
          <motion.p
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 1 }}
            className='level-desc'>{getLocalizedString('collection_level_desc')}</motion.p>
          {
            levelData.map((level) => {
              return (
                <BuildingOverviewCTA
                  key={level.slug}
                  link={'/' + buildingId + '/level/' + level.slug.toLowerCase()}
                  heading={level.title}
                  subheading={level.locations.length + ' ' + getLocalizedString('label_art_locations')}
                  colour={'#556d6e'} // TODO: this colour needs to be set in the admin
                  imageUrl={checkImage(level.imageUrl)}
                  imageAlt={level.imageAlt}
                />
              );
            })
          }
        </motion.section>
        <section className='other-container'>
          <motion.div
            initial={{ opacity: 0, x: -100 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 1 }}
            className='other-title tag steel-tag'>{getLocalizedString('label_explore')}</motion.div>
          <BuildingOverviewCTA
            link={'/' + buildingId + '/tour-overview'}
            tag={''}
            heading={getLocalizedString('label_guided_tours')}
            subheading={getLocalizedString('collection_tours_sub_title')}
            colour={'#556d6e'}
            imageUrl={checkImage(tourPageData.acf.hero_image)}
          />
          <BuildingOverviewCTA
            link={'/' + buildingId + '/about'}
            tag={''}
            heading={getLocalizedString('collection_about_title')}
            subheading={getLocalizedString('collection_about_sub_title')}
            colour={'#39607d'}
            imageUrl={checkImage(aboutPageData.acf.hero_image)}
          />
        </section>
        <div className='bottom-line'></div>
      </>
    );
  };

  return (
    <section className='collection-page'>
      {buildPage()}
    </section>
  );
};

export default CollectionPage;