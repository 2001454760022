import ArtLocationDataObject from './ArtLocationDataObject';

class TourDataObject{
  constructor(title, slug, content, imageUrl, imageAlt, locations){
    this.title = title;
    this.slug = slug;
    this.content = content;
    this.imageUrl = imageUrl;
    this.imageAlt = imageAlt;
    this.locations = this.#processLocations(locations);
  }

  #processLocations(data){
    const artLocation = [];

    if(data){
      if(data.length > 0){
        for(let i=0 ; i<data.length ; i++){
          const location = data[i];
    
          const content = location.content_sections;
          const artwork = location.artwork;
    
          // Since these art locations work differently than in themes, we have to generate some info to create an art location.
          const slug = i.toString(); //Generate a slug based of the index
          const imageUrl = (artwork[0].acf.image !== null && artwork[0].acf.image !== undefined) ? artwork[0].acf.image.url : ''; //Use the first artwork in the list as the hero image
          const imageAlt = (artwork[0].acf.image !== null && artwork[0].acf.image !== undefined) ? artwork[0].acf.image.alt : '';

          const imageCredit = (artwork[0].acf.image !== null && artwork[0].acf.image !== undefined) ? artwork[0].acf.image.caption : '';

          let title = null;

          if(location.title_override !== undefined && location.title_override !== false) {
            title = location.title;
          }
    
          const artData = new ArtLocationDataObject(slug, content, '', imageUrl, imageAlt, imageCredit, artwork, title);
    
          artLocation.push(artData);
        }
      }
    }

    return artLocation;
  }
}

export default TourDataObject;