/* eslint-disable no-unused-vars */
import React, { useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { motion } from 'framer-motion';

//Context
import AppDataContext from '../../context/AppDataContext';

//Components
import HeroImage from '../common/HeroImage';
import Text from '../Text';
import ArtistInfo from '../ArtistInfo';
import GalleryPreview from '../Gallery/GalleryPreview';
import Quote from '../common/Quote';
import AudioClip from '../common/AudioClip';
import ArtNavButton from '../common/ArtNavButton';
import { checkImage } from '../../utils/Utils';

const ArtLocationPage = () => {
  const { buildingId, levelId, tourId, artId } = useParams();

  const { setCurPage, getLevelById, getTourById, getLocalizedString, textComp, quoteData } = useContext(AppDataContext);

  /**
   * -Main content page for an piece of artwork
   * -Display a hero image of the artwork
   * -Display the artist and artwork information
   * -Display a button to open up a map modal
   * -The body of the content should be dynamically built using information from the REST API
   *  -loop / .map through the data and display the proper component
   * Create a gallery id dynamically for this page (if needed), pass it as a prop
   */
  useEffect(() => {
    const page = {
      type: 1,
      label: 'Art Location'
    };

    setCurPage(page);

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant'
    });
  }, []);

  const checkLink = () => {
    let segment = '';
    if (levelId !== undefined) {
      if (levelId.length > 0) {
        segment = '/level/' + levelId;
      }
    }

    if (tourId !== undefined) {
      if (tourId.length > 0) {
        segment = '/guided-tour/' + tourId;
      }
    }

    return '/' + buildingId + segment;
  };

  const buildPage = () => {
    const data = (levelId !== undefined) ? getLevelById(levelId) : getTourById(tourId);

    const artData = data.locations.filter(obj => {
      return obj.slug.toString() === artId;
    })[0];

    const curLocationIndex = data.locations.indexOf(artData);
    const prevLocationIndex = curLocationIndex - 1;
    let prevLabel = getLocalizedString('label_level_overview');
    let prevLink = checkLink();
    if (prevLocationIndex >= 0) {
      prevLabel = getLocalizedString('label_prev_artwork');
      prevLink = checkLink() + '/art/' + data.locations[prevLocationIndex].slug;
    }

    const nextLocationIndex = curLocationIndex + 1;
    let nextLabel = getLocalizedString('label_level_overview');
    let nextLink = checkLink();
    if (nextLocationIndex < data.locations.length) {
      nextLabel = getLocalizedString('label_next_artwork');
      nextLink = checkLink() + '/art/' + data.locations[nextLocationIndex].slug;
    }

    let previewImageUrl = '';
    let previewImageAlt = '';
    // console.log(artData.artwork[0].acf.image.url);
    if (artData.artwork[0].acf.image) {
      previewImageUrl = checkImage(artData.artwork[0].acf.image.url);
      previewImageAlt = artData.artwork[0].acf.image.alt;
    }

    if (artData === undefined) {
      return (
        <p>There was an error retrieving the art data</p>
      );
    } else {
      return (
        <>
          <HeroImage imageUrl={checkImage(artData.imageUrl)} altText={artData.imageAlt} shouldAnimate={true} />
          <ArtistInfo
            tag={getLocalizedString('label_level') + ' ' + artData.level}
            artist={artData.getArtArtist()}
            title={artData.getArtLocationTitle()}
            date={artData.getArtDates()}
            medium={artData.getArtMediums()}
            credit={artData.imageCredit}
            mapLink={checkLink() + '/art/' + artId + '/map'}
          />
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 1 }}

            className='level-title tag steel-tag'
          >
            {getLocalizedString('label_about_work')}
          </motion.div>
          {
            // Loop through and build out the different component types
            (artData.content) && artData.content.map((section, index) => {
              switch (section.acf_fc_layout) {
                case 'text':
                  return <Text key={section.acf_fc_layout + '-' + index} heading={section.heading} content={section.content} />;
                case 'quote':
                  return <Quote
                    key={section.acf_fc_layout + '-' + index}
                    quote={section.quote}
                    name={section.quotee}
                  />;
                case 'audio':
                  return <AudioClip
                    key={section.acf_fc_layout + '-' + index}
                    title={section.title}
                    src={section.audio}
                  />;
                case 'video':
                  return <p key={section.acf_fc_layout + '-' + index}>Insert Video Component here</p>;
                case 'image_gallery':
                  return <GalleryPreview
                    key={section.acf_fc_layout + '-' + index}
                    artId={artId}
                  />;
              }
            })
          }
          {
            // Currently can only have one gallery since the images are being pulled from the list of artwork contained in the art location. So the galleryId value isn't needed but we just use the same ID as the artId so that there is a value for the prop
          }
          <GalleryPreview
            artId={artId}
            galleryId={artId}
            imageUrl={previewImageUrl}
            imageAlt={previewImageAlt}
            numArt={artData.artwork.length}
          />
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 1 }}

            className='level-title tag steel-tag'
          >
            {getLocalizedString('label_about_artist')}
          </motion.div>
          <Text heading={''} content={artData.getAboutArtist()} />
          <div className='nav-buttons'>
            <ArtNavButton link={prevLink} direction={-1} label={prevLabel} />
            <ArtNavButton link={nextLink} direction={1} label={nextLabel} />
          </div>
        </>
      );
    }
  };

  return (
    <div className='art-location-page'>
      {buildPage()}
    </div>
  );
};

export default ArtLocationPage;