class ArtLocationDataObject {
  constructor(slug, content, level, imageUrl, imageAlt, imageCredit, artwork, title) {
    this.slug = slug;
    this.content = content;
    this.imageUrl = imageUrl;
    this.imageAlt = imageAlt;
    this.imageCredit = imageCredit || '';
    this.artwork = artwork;
    this.level = level;
    this.title = title;

    this.artTitles = [];
    this.artIds = [];
    this.artArtists = [];
    this.artAboutArtists = [];
    this.artDates = [];
    this.artMediums = [];
    this.artEditions = [];
    this.artDimensions = [];

    this.tourCtaData = {
      themeSlug: null,
      artLocationSlug: null
    };

    if (this.artwork) {
      if (this.artwork.length > 0) {
        this.#processArtwork();
      }
    }
  }

  #processArtwork() {
    // Populate arrays for values that need to list all of the artwork in the location
    this.artwork.map((art) => {
      const title = art.post_title;
      const id = art.post_name;
      const artist = art.acf.artist.post_title;
      const aboutArtist = (art.acf.artist.acf) ? art.acf.artist.acf.about_the_artist : '';
      const artDate = art.acf.date;
      const artMedium = art.acf.medium;
      const artEdition = art.acf.edition;
      const artDimensions = art.acf.dimensions;

      if (title !== undefined && title !== '') {
        if (!this.artTitles.includes(title)) {
          this.artTitles.push(title);
        }
      }

      if (id !== undefined && id !== '') {
        this.artIds.push(id);
      }

      if (artist !== undefined && artist !== '') {
        if (!this.artArtists.includes(artist)) {
          this.artArtists.push(artist);
        }
      }

      if (aboutArtist !== undefined && aboutArtist !== '') {
        if (!this.artAboutArtists.includes(aboutArtist)) {
          this.artAboutArtists.push(aboutArtist);
        }
      }

      if (artDate !== undefined && artDate !== '') {
        if (!this.artDates.includes(artDate)) {
          this.artDates.push(artDate);
        }
      }

      if (artMedium !== undefined && artMedium !== '') {
        if (!this.artMediums.includes(artMedium)) {
          this.artMediums.push(artMedium);
        }
      }

      if (artEdition !== undefined && artEdition !== '') {
        if (!this.artEditions.includes(artEdition)) {
          this.artEditions.push(artEdition);
        }
      }

      if (artDimensions !== undefined && artDimensions !== '') {
        if (!this.artDimensions.includes(artDimensions)) {
          this.artDimensions.push(artDimensions);
        }
      }
    });
  }

  getArtArtist() {
    if (this.artArtists.length <= 0) {
      return 'Artist not set';
    }
    return this.artArtists[0]; // Assume the first artist even if there's multiple artists
  }

  getAboutArtist() {
    if (this.artAboutArtists.length <= 0) {
      return 'About Artist not set';
    }
    return this.artAboutArtists[0]; // Assume the first entry even if there's multiple artists
  }

  getArtLocationTitle() {
    if(this.title !== null){
      return this.title;
    }
    return this.artTitles.join('; ');
  }

  getArtDates() {
    return this.artDates.join(', ');
  }

  getArtMediums() {
    return this.artMediums.join(', ');
  }

  getArtEditions() {
    if (this.artEditions.length <= 0) {
      return '';
    }

    return this.artEditions.join(', ');
  }
}

export default ArtLocationDataObject;