import React, { useContext, useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import axios from 'axios';

import AppDataContext from './context/AppDataContext';

import Header from './components/header/Header';
import Audio from './components/Audio';

import Gallery from './components/Gallery/Gallery';
import MainBody from './components/pages/MainBody';
import BuildSelectPage from './components/pages/BuildingSelectPage';
import CollectionPage from './components/pages/CollectionPage';
import AboutPage from './components/pages/AboutPage';
import ArtLocationPage from './components/pages/ArtLocationPage';
import GuidedToursPage from './components/pages/GuidedToursPage';
import LevelOverviewPage from './components/pages/LevelOverviewPage';
import TourOverviewPage from './components/pages/TourOverviewPage';
import Map from './components/pages/Map';

const App = () => {
  const [message, setMessage] = useState('Loading Content...');
  const {setPageScrollY} = useContext(AppDataContext);

  const [isLoaded, setIsLoaded] = useState(false);
  const [buildingList, setBuildingList] = useState([]);
  const [buildingSelectStrings, setBuildingSelectStrings] = useState();

  useEffect(() => {
    //Query Rest API. This may need to be moved. REST API should be queried once the building has been selected
    if(!isLoaded){
      // Query the main WP site for the list of buildings
      try{
        // eslint-disable-next-line no-undef
        axios.get(`${process.env.REACT_APP_API_URL}/wp/wp-json/wp/v2/sites`)
          .catch((error) =>{
            if(error){
              setMessage('There was an error loading the content, please try again');
            }
          })
          .then( res => {
            const list = res.data;
  
            setIsLoaded(true);
            setBuildingList(list.sites);
            setBuildingSelectStrings(list.buildingSelect);
          });
      }catch(e){
        console.log(e);
      }
    }

    window.addEventListener('scroll', onScroll);
  }, []);

  const onScroll = () => {
    setPageScrollY(window.scrollY);
  };

  return (
    <main className='main-container'>
      {(isLoaded)? 
        <>
          <BrowserRouter>
            <Routes>
              <Route path='/' element={
                <BuildSelectPage list={buildingList} strings={buildingSelectStrings} />
              }/>
              <Route path='/:buildingId/collection' element={
                <>
                  <Header />
                  <MainBody>
                    <CollectionPage />
                  </MainBody>
                  <Audio />
                </>
              }/>
              <Route path='/:buildingId/about/' element={
                <>
                  <Header />
                  <MainBody>
                    <AboutPage />
                  </MainBody>
                </>
              }/>
              <Route path='/:buildingId/level/:levelId' element={
                <>
                  <Header />
                  <MainBody>
                    <LevelOverviewPage />
                  </MainBody>
                </>
              }/>
              <Route path='/:buildingId/tour-overview' element={
                <>
                  <Header />
                  <MainBody>
                    <TourOverviewPage />
                  </MainBody>
                </>
              }/>
              <Route path='/:buildingId/guided-tour/:tourId' element={
                <>
                  <Header />
                  <MainBody>
                    <GuidedToursPage />
                  </MainBody>
                </>
              }/>
              <Route path='/:buildingId/level/:levelId/art/:artId' element={
                <>
                  <Header />
                  <MainBody>
                    <ArtLocationPage />
                  </MainBody>
                  <Audio />
                </>
              }/>
              <Route path='/:buildingId/level/:levelId/art/:artId/map' element={
                <>
                  <Header hasBackground={true} />
                  <Map />
                </>
              }/>
              <Route path='/:buildingId/level/:levelId/art/:artId/gallery/:galleryId' element={
                <>
                  <Header hasBackground={true}/>
                  <Gallery /> 
                </>
              }/>
              <Route path='/:buildingId/guided-tour/:tourId/art/:artId' element={
                <>
                  <Header />
                  <MainBody>
                    <ArtLocationPage />
                  </MainBody>
                  <Audio />
                </>
              }/>
              <Route path='/:buildingId/guided-tour/:tourId/art/:artId/map' element={
                <>
                  <Header hasBackground={true} />
                  <Map />
                </>
              }/>
              <Route path='/:buildingId/guided-tour/:tourId/art/:artId/gallery/:galleryId' element={
                <>
                  <Header hasBackground={true}/>
                  <Gallery /> 
                </>
              }/>
              <Route path='*' element={
                <p>404 page</p>
              }/>
            </Routes>
          </BrowserRouter>
        </>
        :
        <p>{message}</p>
      }
    </main>
  );
};

export default App;
