import ArtLocationDataObject from './ArtLocationDataObject';

class ThemeDataObject{ 
  constructor(title, slug, content, imageUrl, imageAlt, locations, themeLevel, mapUrl){
    this.title = title;
    this.slug = slug;
    this.content = content;
    this.imageUrl = imageUrl;
    this.imageAlt = imageAlt;
    this.themeLevel = themeLevel;
    this.locations = this.#processLocations(locations);
    this.mapUrl = mapUrl;
  }

  #processLocations(data){
    const artLocation = [];

    if(data){
      if(data.length > 0){
        data.map( (location, index) => {
          const id = index + 1;
          const slug = (index < 10) ? '0' + id : id;
          const content = location.acf.content_sections;
          const imageUrl = location.acf.image.url;
          const imageAlt = location.acf.image.alt;
          const imageCredit = location.acf.image.caption;
          const artwork = location.acf.artwork;

          let title = null;

          if(location.acf.title_override !== undefined && location.acf.title_override !== false) {
            title = location.acf.title;
          }
    
          const data = new ArtLocationDataObject(slug, content, this.themeLevel, imageUrl, imageAlt, imageCredit, artwork, title);
    
          artLocation.push(data);
        });
      }
    }

    return artLocation;
  }

  getArtists(){
    const artists = [];

    this.locations.acf.artwork.forEach( (art) => {
      console.log(art);
    });

    return artists;
  }

}

export default ThemeDataObject;