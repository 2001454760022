import React, {useState, useRef, useContext, useEffect} from 'react';
import { useParams } from 'react-router-dom';
import Carousel from 'react-bootstrap/Carousel';
import 'bootstrap/dist/css/bootstrap.min.css';

//Context
import AppDataContext from '../../context/AppDataContext';

function Gallery() {
  const {buildingId, levelId, tourId, artId, galleryId} = useParams();
  const {hasDataLoaded, loadAppData, setCurPage, getLevelGalleryData, getTourGalleryData, selectedLanguage, galleryLoading, getLocalizedString} = useContext(AppDataContext);

  const carouselRef = useRef(null);

  const [isLoaded, setIsLoaded] = useState(false);
  const [index, setIndex] = useState(0);
  const [galleryImages, setGalleryImages] = useState([]);
  const [caption, setCaption] = useState('');
  const [artist, setArtist] = useState('');
  const [title, setTitle] = useState('');
  const [date, setDate] = useState('');
  const [medium, setMedium] = useState('');
  const [edition, setEdition] = useState('');
  const [dimension, setDimensions] = useState('');
  const [credit, setCredit] = useState('');

  const [fadeIn, setFadeIn] = useState(false);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
    setCaption(galleryImages[selectedIndex].caption);
    setArtist(galleryImages[selectedIndex].artist);
    setTitle(galleryImages[selectedIndex].title);
    setDate(galleryImages[selectedIndex].date);
    setMedium(galleryImages[selectedIndex].medium);
    setEdition(galleryImages[selectedIndex].edition);
    setDimensions(galleryImages[selectedIndex].dimensions);
    setCredit(galleryImages[selectedIndex].credit);
  };

  const onPrevClick = () => {
    carouselRef.current.prev();
    handleFadeIn();
  };
  const onNextClick = () => {
    carouselRef.current.next();
    handleFadeIn();
  };

  // fade in
  const handleFadeIn = () => {
    setFadeIn(true);
    return setTimeout(() => {
      setFadeIn(false);
    }, 800);
  };

  useEffect(() => {
    if(!hasDataLoaded){
      //If data hasn't loaded, load it now
      loadAppData(buildingId);
    }else{
      parseData();
      handleFadeIn();
    }

  }, []);

  // Wait until data is loaded and set the local page state to render
  useEffect(()=>{
    const page = {
      type: 1,
      label: 'Gallery'
    };

    setCurPage(page);

    window.scrollTo(0, 0);

    if(hasDataLoaded){
      parseData();
      handleFadeIn();
      setIsLoaded(true);
    }
  },[hasDataLoaded]);

  useEffect( () => {
    if(hasDataLoaded){
      parseData();
    }
  }, [selectedLanguage]);

  const parseData = () => {
    const data = (levelId !== undefined) ? getLevelGalleryData(levelId, artId, galleryId) : getTourGalleryData(tourId, artId, galleryId);
    
    if(data !== null){
      setGalleryImages(data);
      setCaption(data[index].caption);
      setArtist(data[index].artist);
      setTitle(data[index].title);
      setDate(data[index].date);
      setMedium(data[index].medium);
      setEdition(data[index].edition);
      setDimensions(data[index].dimensions);
      setCredit(data[index].credit);
    }
  };

  if(galleryLoading && !isLoaded){
    // Should we have a spinner?
    return <p>loading...</p>;
  } else {
    return (
      <div className="gallery-component">
        <Carousel
          interval={null}
          ref={carouselRef}
          activeIndex={index} 
          onSelect={handleSelect}
          indicators={false}
          controls={false}
          className="art-gallery">
          {
            galleryImages.map((item, index) => (
           
              <Carousel.Item key={index + item}>
                <div className="image-container">
                  <img
                    className="d-block"
                    src={item.imageUrl}
                    alt={item.imageAlt}
                  />
                </div>
              </Carousel.Item>         
            ))
          }
        </Carousel>
        <div className="gallery-controls">
          {
            (galleryImages !== undefined && galleryImages.length > 1) &&
            <button className="control btn-prev" onClick={onPrevClick}>{getLocalizedString('label_prev')}</button>
          }
          <p className='slider-pagination'>{`${index + 1}/${galleryImages.length}`}</p>
          {
            (galleryImages !== undefined && galleryImages.length > 1) &&
            <button className="control btn-next" onClick={onNextClick}>{getLocalizedString('label_next')}</button>
          }
        </div>      
        <div className="gallery-caption">
          <div className="gallery-counter">
          </div>
          <div className={`caption ${fadeIn && 'fade-in'}`} dangerouslySetInnerHTML={{__html: caption}}></div>
        </div>
        <div className="gallery-details">
          {
            (artist !== undefined && artist.length > 0) &&
            <div className="artist">
              <p className="list-title">{getLocalizedString('label_artist')}</p>
              <p className="list-detail">{artist}</p>
            </div>
          }
          {
            (title !== undefined && title.length > 0) &&
            <div className="title">
              <p className="list-title">{getLocalizedString('label_title')}</p>
              <p className="list-detail">{title}</p>
            </div>
          }
          {
            (date !== undefined && date.length > 0) &&
            <div className="date">
              <p className="list-title">{getLocalizedString('label_date')}</p>
              <p className="list-detail">{date}</p>
            </div>
          }
          {
            (medium !== undefined && medium.length > 0) &&
            <div className="medium">
              <p className="list-title">{getLocalizedString('label_medium')}</p>
              <p className="list-detail">{medium}</p>
            </div>
          }
          {
            (edition !== undefined && edition.length > 0) &&
            <div className="edition">
              <p className="list-title">{getLocalizedString('label_edition')}</p>
              <p className="list-detail">{edition}</p>
            </div>
          }
          {
            (dimension !== undefined && dimension.length > 0) &&
            <div className="dimension">
              <p className="list-title">{getLocalizedString('label_dimensions')}</p>
              <p className="list-detail">{dimension}</p>
            </div>
          }
          {
            (credit !== undefined && credit.length > 0) &&
            <div className="credit">
              <p className="list-title">{getLocalizedString('label_credit')}</p>
              <p className="list-detail">{credit}</p>
            </div>
          }
        </div>
        <div className="bottom-line"></div>
      </div>   
    );
  }  
}

export default Gallery;
