import React, {useRef, useContext} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {motion} from 'framer-motion';
import AppDataContext from '../../context/AppDataContext';

const ImageCTA = ({link, tag, title, subtitle, ctaLinkLabel, imageUrl}) => {
  const {prevButtonRect} = useContext(AppDataContext);

  const imageRef = useRef(null);

  const handleOnClick = () => {
    const rect = imageRef.current.getBoundingClientRect();
    prevButtonRect.current = rect;
  };

  return(
    <Link to={link}>
      <div
        className='image-cta'
        onClick={handleOnClick}
      >
        <div
          ref={imageRef}
          className='image-container'
        >
          <img src={imageUrl} alt='need alt text' />
        </div>
        <motion.h2
          initial={{ opacity: 0, x: -50 }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1 }}
        >
          {title}
        </motion.h2>
        {(subtitle && subtitle.length > 0) && <motion.h4
          initial={{ opacity: 0, x: -50 }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1 }}
        >{subtitle}</motion.h4>}
        <motion.div
          initial={{ opacity: 0, x: -50 }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1 }}
          className='button-foot'>
          <div className="tag bronze-tag" dangerouslySetInnerHTML={{__html: tag}}></div>
          <span className='text-link cta-link'>{ctaLinkLabel}</span>
        </motion.div>
      </div>
    </Link>
  );
};

ImageCTA.propTypes = {
  link: PropTypes.string.isRequired,
  tag: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  ctaLinkLabel: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired
};

export default ImageCTA;