import React, {createContext, useState, useRef} from 'react';
/* eslint-disable no-unused-vars */
import axios from 'axios';
import PropTypes from 'prop-types';

import LoadedDataObject from '../data/LoadedDataObject';
import ThemeDataObject from '../data/ThemeDataObject';
import TourDataObject from '../data/TourDataObject';

//Create Context
export const AppDataContext = createContext();

//Create Provider
export const AppDataProvider = ({children}) => {
  const [hasDataLoaded, setHasDataLoaded] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState('en');

  //Header page tracking
  const [curPage, setCurPage] = useState(null);
  const [pageScrollY, setPageScrollY] = useState(0);

  //Audio Player
  const [audioSrc, setAudioSrc] = useState('');
  const [audioTitle, setAudioTitle] = useState('Test Title');
  
  // Ref object that holds all the loaded JSON results from WP
  const loadedAppData = useRef({
    collectionPage: null,
    aboutPage: null,
    tourOverviewPage: null,
    levels: null,
    tours: null
  });

  const prevButtonRect = useRef(null);
  const loadedStrings = useRef(null);

  let curBuilding;

  let hasStringsLoaded = false;
  let hasCollectionLoadedEN, hasTourLoadedEN, hasAboutLoadedEN = false;
  let hasCollectionLoadedFR, hasTourLoadedFR, hasAboutLoadedFR = false;

  const resetCompletionFlags = () => {
    hasCollectionLoadedEN = false;
    hasAboutLoadedEN = false;
    hasTourLoadedEN = false;

    hasCollectionLoadedFR = false;
    hasAboutLoadedFR = false;
    hasTourLoadedFR = false;

    hasStringsLoaded = false;
  };

  const loadAppData = (building) => {
    curBuilding = building;
    resetCompletionFlags();
    setHasDataLoaded(false);
    loadStrings(curBuilding);
    loadCollectionPageData(curBuilding, 'en');
    loadAboutPageData(curBuilding, 'en');
    loadTourOverviewPageData(curBuilding, 'en');
  };

  const loadStrings = (building) => {
    // eslint-disable-next-line no-undef
    axios.get(`${process.env.REACT_APP_API_URL}/${building}/wp-json/wp/v2/misc-strings`).then((res) => {
      if(res.data){
        loadedStrings.current = res.data;
        hasStringsLoaded = true;
      }
    }).catch((err) => {
      throw new Error('Error while retrieving misc strings data: ' + err);
    });
  };

  const loadCollectionPageData = (building, locale) => {
    //Get Content for the Collection page
    // eslint-disable-next-line no-undef
    axios.get(`${process.env.REACT_APP_API_URL}/${building}/wp-json/wp/v2/pages?slug=home&lang=${locale}`).then((res) => {
      //Ensure that the queried data has at least one result
      if(res.data.length > 0){
        if(loadedAppData.current['collectionPage'] === null){
          const obj = new LoadedDataObject('collectionPage');
          obj.setJsonContent(res.data[0], locale);
          loadedAppData.current['collectionPage'] = obj;
        }else{
          loadedAppData.current['collectionPage'].setJsonContent(res.data[0], locale);
        }

        //Now that the collection page has loaded, we need all the levels
        loadLevelData(building, locale);
      }else{
        throw new Error('No collection page data found');
      }
    }).catch((err) => {
      throw new Error('Error while retrieving home page data: ' + err);
    });
  };

  const loadLevelData = (building, locale) => {
    // eslint-disable-next-line no-undef
    axios.get(`${process.env.REACT_APP_API_URL}/${building}/wp-json/wp/v2/theme?lang=${locale}`).then((res) => {
      if(res.data.length > 0){

        const processedData = processThemeData(res.data);
        
        if(loadedAppData.current['levels'] === null){
          const obj = new LoadedDataObject('levels');
          obj.setJsonContent(processedData, locale);

          loadedAppData.current['levels'] = obj;
        }else{
          loadedAppData.current['levels'].setJsonContent(processedData, locale);
        }

        // Flag that the collection data has all loaded
        if(locale === 'en'){
          hasCollectionLoadedEN = true;
        }else{
          hasCollectionLoadedFR = true;
        }
        checkLoadComplete(locale);
      }else{
        throw new Error('No level data found');
      }
    }).catch((err) => {
      throw new Error('Error while retrieving level data: ' + err);
    });
  };

  const processThemeData = (data) => {
    const processedThemes = [];

    data.map( (theme) => {
      const title = theme.title.rendered;
      const slug = theme.slug;
      const content = theme.acf.content_sections;
      const image = theme.acf.image.url;
      const imageAlt = theme.acf.image.alt;
      const locations = theme.acf.Locations;
      const map = theme.acf.map;

      let themeLevel = '';
      if(locations && locations.length > 0){
        themeLevel = locations[0].acf.level; // Init the level to the value found in the first element

        // Loop through all the locations to ensure there are no differences in the level. If there is a difference assign a '-' value to indicate that there's an issue
        locations.map( (loc) => {
          if(themeLevel !== loc.acf.level){
            themeLevel = '-';
          }
        });
      }
      
      const themeData = new ThemeDataObject(title, slug, content, image, imageAlt, locations, themeLevel, map);

      processedThemes.push(themeData);
    });

    // Sort the themes
    processedThemes.sort( (a, b) => (parseInt(a.themeLevel) > parseInt(b.themeLevel)) ? 1 : ( (parseInt(b.themeLevel) > parseInt(a.themeLevel)) ? -1 : 0) );

    return processedThemes;
  };

  const loadAboutPageData = (building, locale) => {
    setHasDataLoaded(false);

    //Get Content for the About page
    // eslint-disable-next-line no-undef
    axios.get(`${process.env.REACT_APP_API_URL}/${building}/wp-json/wp/v2/pages?slug=about&lang=${locale}`).then((res) => {
      //Ensure that the queried data has at least one result
      if(res.data.length > 0){
        if(loadedAppData.current['aboutPage'] === null){
          const obj = new LoadedDataObject('aboutPage');
          obj.setJsonContent(res.data[0], locale);
          loadedAppData.current['aboutPage'] = obj;
        }else{
          loadedAppData.current['aboutPage'].setJsonContent(res.data[0], locale);
        }

        if(locale === 'en'){
          hasAboutLoadedEN = true;
        }else{
          hasAboutLoadedFR = true;
        }
        
        checkLoadComplete(locale);
      }else{
        throw new Error('No collection page data found');
      }
    }).catch((err) => {
      throw new Error('Error while retrieving about page data: ' + err);
    });
  };

  const loadTourOverviewPageData = (building, locale) => {
    setHasDataLoaded(false);

    //Get Content for the About page
    // eslint-disable-next-line no-undef
    axios.get(`${process.env.REACT_APP_API_URL}/${building}/wp-json/wp/v2/pages?slug=tour&lang=${locale}`).then((res) => {
      //Ensure that the queried data has at least one result
      if(res.data.length > 0){
        if(loadedAppData.current['tourOverviewPage'] === null){
          const obj = new LoadedDataObject('tourOverviewPage');
          obj.setJsonContent(res.data[0], locale);
          loadedAppData.current['tourOverviewPage'] = obj;
        }else{
          loadedAppData.current['tourOverviewPage'].setJsonContent(res.data[0], locale);
        }
      }else{
        throw new Error('No Tour Overview page data found');
      }

      // Load the tours next
      loadTourData(building, locale);
    }).catch((err) => {
      throw new Error('Error while retrieving tour overview page data: ' + err);
    });
  };

  const loadTourData = (building, locale) => {
    // eslint-disable-next-line no-undef
    axios.get(`${process.env.REACT_APP_API_URL}/${building}/wp-json/wp/v2/tour?lang=${locale}`).then((res) => {
      if(res.data.length > 0){
        const processedData = processTourData(res.data);

        if(loadedAppData.current['tours'] === null){
          const obj = new LoadedDataObject('tours');
          obj.setJsonContent(processedData, locale);

          loadedAppData.current['tours'] = obj;
        }else{
          loadedAppData.current['tours'].setJsonContent(processedData, locale);
        }

        if(locale === 'en'){
          hasTourLoadedEN = true;
        }else{
          hasTourLoadedFR = true;
        }
        checkLoadComplete(locale);
      }else{
        console.log('No tour data found for: ' + locale);
        if(locale === 'en'){
          hasTourLoadedEN = true;
        }else{
          hasTourLoadedFR = true;
        }
        checkLoadComplete(locale);
        // throw new Error('No tour data found');
      }
    }).catch((err) => {
      throw new Error('Error while retrieving tour data: ' + err);
    });
  };

  const processTourData = (data) => {
    const processedTours = [];
    
    data.map( (tour) => {
      const title = tour.title.rendered;
      const slug = tour.slug;
      const content = tour.acf.content_sections;
      const image = (tour.acf.Image) ? tour.acf.Image.url : '';
      const imageAlt = (tour.acf.Image) ? tour.acf.Image.alt : '';
      const locations = tour.acf.location;

      const tourData = new TourDataObject(title, slug, content, image, imageAlt, locations);

      processedTours.push(tourData);
    });

    // processedTours.sort( (a, b) => (a.title > b.title) ? 1 : ( (b.title > a.title) ? -1 : 0) );

    return processedTours;
  };

  const checkLoadComplete = (locale) => {
    if(locale === 'en'){
      // If the locale is en, once all sections have loaded, load the fr content
      if(hasCollectionLoadedEN && hasAboutLoadedEN && hasTourLoadedEN && hasStringsLoaded){
        console.log('EN data loaded, load FR data');
        loadCollectionPageData(curBuilding, 'fr');
        loadAboutPageData(curBuilding, 'fr');
        loadTourOverviewPageData(curBuilding, 'fr');
      }
    }else{
      // If the locale is fr, then all conent has loaded
      if(hasCollectionLoadedFR && hasAboutLoadedFR && hasTourLoadedFR && hasStringsLoaded){
        console.log('FR data loaded. All data loaded, set data loaded complete');
        setHasDataLoaded(true);
      }
    }
  };

  const getLevelById = (id) => {
    const data = loadedAppData.current['levels'].getJsonContent(selectedLanguage);

    const result = data.filter(obj => {
      return obj.slug.toLowerCase() === id;
    });

    return result[0];
  };

  const getTourById = (id) => {
    const data = loadedAppData.current['tours'].getJsonContent(selectedLanguage);

    const result = data.filter(obj => {
      return obj.slug.toLowerCase() === id;
    });
    
    return result[0];
  };

  const getThemeLevelById = (id) => {
    // Ensure that there's at least 1 id in the array
    if(id.length >= 1){
      // Only use the first element
      const artId = id[0];

      let foundThemeSlug = '';
      let foundLevel = '';
      let foundArtLocationSlug = '';

      const data = loadedAppData.current['levels'].getJsonContent(selectedLanguage);
  
      // loop through all the theme data
      for(let i = 0 ; i<data.length ; i++){
        const theme = data[i];
        const loc = theme.locations;

        foundThemeSlug = theme.slug;
        foundLevel = theme.themeLevel;
        
        // loop through each themes art locations
        for(let j=0 ; j<loc.length ; j++){
          const artLoc = loc[j];
          const artwork = artLoc.artwork;

          foundArtLocationSlug = artLoc.slug;
  
          // filter the art locations artwork for the id
          const result = artwork.filter( obj => {
            return obj.post_name.toLowerCase() === artId;
          });
  
          // As long as the results from the filter has at least 1 element, return it
          if(result.length > 0){
            return {
              level: foundLevel,
              theme: foundThemeSlug,
              art: foundArtLocationSlug
            };
          }
        }
      }
    }

    // take note which theme, floor it's on, and the art location slug/id so that we can link to it again if they click on "More about the artwork"

    // Return a '-' for the theme to signify something went wrong
    return {
      level: '-',
      theme: null,
      art: null
    };
  };

  const getTourGalleryData = (tourId, artId, galleryId) => {
    const galleryData = [];
    const tourData = getTourById(tourId);
    const artResults = tourData.locations.filter( obj => {
      return obj.slug.toLowerCase() === artId;
    });

    if(artResults.length > 0){
      const artContent = artResults[0].content;
      const gallery = artContent[parseInt(galleryId)].gallery;

      for(let i=0 ; i<gallery.length ; i++){
        const data = {
          imageUrl: gallery[i].url,
          imageAlt: '',
          caption: gallery[i].caption,
          artist: '',
          title: '',
          date: '',
          medium: '',
          dimensions: '',
          credit: ''
        };

        galleryData.push(data);
      }

      return galleryData;
    }
    
    return null;
  };

  const getLevelGalleryData = (levelId, artId, galleryId) => {
    const galleryData = [];
    const levelData = getLevelById(levelId);
    const artResults = levelData.locations.filter( obj => {
      return obj.slug.toString().toLowerCase() === artId;
    });

    if(artResults.length > 0){
      const artContent = artResults[0];

      for(let i=0 ; i<artContent.artwork.length ; i++){
        const artwork = artContent.artwork[i];
        const data = {
          imageUrl: (artwork.acf.image) ? artwork.acf.image.url : '',
          imageAlt: (artwork.acf.image) ? artwork.acf.image.alt : '',
          caption: artwork.acf.caption,
          artist: artwork.acf.artist.post_title,
          title: artwork.post_title,
          date: artwork.acf.date,
          medium: artwork.acf.medium,
          edition: artwork.acf.edition,
          dimensions: artwork.acf.dimensions,
          credit: artwork.acf.credit
        };

        galleryData.push(data);
      }

      return galleryData;
    }

    return null;
  };

  const getLocalizedString = (id) => {
    if(loadedStrings.current){
      const parts = id.split('_');
      
      //The first section of the id is the string group
      const group = parts[0];
      const stringId = id;
      const locale = (selectedLanguage == 'en') ? '' : '_fr';
      
      if(loadedStrings.current[group] === undefined){
        return '[Field Group not found in API]';
      }

      const value = loadedStrings.current[group][stringId + locale];

      if(value === undefined || value.length <= 0){
        return '[Value not found in API]';
      }
  
      return loadedStrings.current[group][stringId + locale];
    }

    return '[Strings not loaded]';
  };

  return(
    <AppDataContext.Provider value={{
      selectedLanguage,
      setSelectedLanguage,
      hasDataLoaded,
      setHasDataLoaded,
      loadAppData,
      loadedAppData,
      prevButtonRect,
      loadedStrings,
      getLevelById,
      getTourById,
      curPage,
      setCurPage,
      pageScrollY,
      setPageScrollY,
      audioSrc,
      setAudioSrc,
      audioTitle,
      setAudioTitle,
      getThemeLevelById,
      getTourGalleryData,
      getLevelGalleryData,
      getLocalizedString,
    }}>
      {children}
    </AppDataContext.Provider>
  );
};

AppDataProvider.propTypes = {
  children: PropTypes.object
};

export default AppDataContext;